import { useQuery } from '@tanstack/react-query';
import type { UseQueryOptions } from '@tanstack/react-query';
import qs from 'qs';

import { getJwt } from '@jane/shared/auth';
import type { Id } from '@jane/shared/models';
import type { UnreviewedProduct } from '@jane/shared/types';
import { parseSearch } from '@jane/shared/util';

import { janeApi } from '../api/janeApi';
import { createUseQueryHook } from '../utils/createUseQueryHook';
import type {
  BrandDiscountStatuses,
  JaneUser,
  UnreviewedProductsProps,
  UserBrandDiscounts,
} from './user.types';
import { userQueryKeys, userUrls } from './user.util';

const fetchJaneUser = async (): Promise<JaneUser> => {
  const params = parseSearch(window.location.search);
  const queryParams = params['ref'] ? { ref: params['ref'] } : null;
  const queryString = queryParams ? `?${qs.stringify(queryParams)}` : '';

  return await janeApi
    .get<JaneUser>(`${userUrls.janeUser()}${queryString}`, getJwt())
    .then((result) => ({
      ...result,
      user: {
        ...result.user,
        authenticated: Boolean(result?.user?.id) ?? false,
      },
    }));
};

const fetchUserBrandDiscounts = async (
  status: BrandDiscountStatuses
): Promise<UserBrandDiscounts> => {
  return await janeApi
    .get<UserBrandDiscounts>(userUrls.userBrandDiscounts(status), getJwt())
    .then((result) => result);
};

export const useJaneUser = createUseQueryHook<void, JaneUser>(
  fetchJaneUser,
  userQueryKeys.janeUser,
  undefined
);

export const useUserBrandDiscounts = ({
  enabled = true,
  janeUserId,
  status,
}: {
  enabled?: boolean;
  janeUserId?: Id;
  status: BrandDiscountStatuses;
}) => {
  return useQuery({
    queryFn: () => fetchUserBrandDiscounts(status),
    queryKey: userQueryKeys.userBrandDiscounts(janeUserId, status),
    enabled: !!janeUserId && Boolean(enabled),
  });
};

export const useJaneUserId = () => {
  const { data: janeUser } = useJaneUser();
  return janeUser?.user?.id;
};

export const useUserSegmentIds = (
  queryOptions: UseQueryOptions<JaneUser, Error> = {}
): number[] => {
  const { data: janeUser } = useJaneUser(undefined, queryOptions);
  return janeUser?.user_segments || [];
};

const fetchUnreviewedProducts = async ({ storeId }: { storeId: Id }) => {
  return await janeApi
    .get<{ products: UnreviewedProduct[] }>(
      userUrls.unreviewedProducts(storeId),
      getJwt()
    )
    .then((result) => {
      return result.products || [];
    });
};

const enableUnreviewedProductsQuery = ({
  userId,
  storeId,
}: UnreviewedProductsProps) => !!userId && !!storeId;

export const useUnreviewedProducts = createUseQueryHook<
  UnreviewedProductsProps,
  UnreviewedProduct[]
>(
  fetchUnreviewedProducts,
  userQueryKeys.unreviewedProducts,
  enableUnreviewedProductsQuery
);
