import {
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useState } from 'react';

import { useUpdateBrandSpecial } from '@jane/gold-manager/data-access';
import { useToast } from '@jane/shared/reefer';

import type {
  SpecialsTableRow,
  SpecialsTableSelection,
  SpecialsTableSort,
  UseSpecialsTableProps,
} from './specialsTable.types';
import { useSpecialsTableColumns } from './useSpecialsTableColumns';

export const useSpecialsTable = ({
  bulkEdit,
  ...props
}: UseSpecialsTableProps) => {
  const [sorting, setSorting] = useState<SpecialsTableSort>([
    { id: 'start_time', desc: true },
  ]);
  const [rowSelection, setRowSelection] = useState<SpecialsTableSelection>({});

  const toast = useToast();

  const { mutateAsync: updateBrandSpecial } = useUpdateBrandSpecial({
    onError: () => {
      toast.add({
        label: 'Error updating special',
        variant: 'error',
      });
    },
    onSuccess: () => {
      toast.add({
        label: 'Special was successfully updated',
        variant: 'success',
      });
    },
  });

  const onSetEnabled = (special: SpecialsTableRow) => {
    const { product_brand_id, special_type, ...rest } = special.original;

    const isEnabled = special.original.enabled;
    const hasLocationId = special.original.aeropay_location_id;

    if (!isEnabled && !hasLocationId) {
      toast.add({
        label: 'Please set a location id to enable this special',
        variant: 'error',
      });
      return;
    }

    const patchedSpecial = {
      ...rest,
      enabled: !special.original.enabled,
    };

    updateBrandSpecial(patchedSpecial);
  };

  const columns = useSpecialsTableColumns({
    bulkEdit,
    onSetEnabled,
  });

  return useReactTable({
    columns,
    state: { sorting, rowSelection },
    onRowSelectionChange: setRowSelection,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getRowId: (row) => row?.id?.toString(),
    ...props,
  });
};
