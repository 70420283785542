import dayjs from 'dayjs';

import {
  type ApiFlightSummary,
  useGetCampaignFlights,
} from '@jane/ad-manager/data-access';
import { DEFAULT_START_DATE, useFilterParams } from '@jane/ad-manager/hooks';
import { useSelectedBrandContext } from '@jane/ad-manager/providers';
import {
  formatCurrency,
  formatISODate,
  formatPacificTimeToUtc,
  formatPercent,
} from '@jane/ad-manager/util';
import { brandsPaths } from '@jane/brands/util';
import {
  Box,
  Breadcrumbs,
  Button,
  Flex,
  Link,
  Tag,
  Typography,
} from '@jane/shared/reefer';

import { useCampaignFlightsTable } from './CampaignFlightList/useCampaignFlightsTable';
import { ColumnVisibilityPopover } from './ColumnVisibilityPopover';
import { EmptyTableState } from './EmptyTableState';
import { StatisticsDisplay } from './StatisticsDisplay';
import { StatisticsFilters } from './StatisticsFilters';
import { Table } from './Table';

// placeholder example campaign statistics
const placeholderCampaignStatistics = [
  {
    label: 'ROAS',
    valueDisplay: formatCurrency(30.02),
  },
  {
    label: 'Ads live',
    valueDisplay: 4,
  },
  {
    label: 'Impressions',
    valueDisplay: '100K',
  },
  {
    label: 'Customers impressed',
    valueDisplay: '456',
  },
  {
    label: 'Total billable',
    valueDisplay: formatCurrency(10345),
  },
  {
    label: 'Saw ad conversion',
    valueDisplay: formatPercent(0.3),
  },
  {
    label: 'Target budget',
    valueDisplay: formatCurrency(20000),
  },
];
const PLACEHOLDER_CAMPAIGN_ID = 1;

const CAMPAIGN_FLIGHTS_COLUMN_VISIBILITY_OPTIONS = [
  'states',
  'stores',
  'dateRange',
  'model',
  'products',
  'placement',
  'budget',
  'spend',
  'bid',
  'frequencyCap',
  'impressions',
  'displaysOn',
];

export const CampaignFlightList = () => {
  const { selectedBrand } = useSelectedBrandContext();
  const { filterParams, onChangeFilterParams } = useFilterParams();

  const {
    data: campaignFlightsData,
    isLoading: isCampaignFlightsLoading,
    error: fetchCampaignFlightsError,
    refetch: refetchCampaignFlights,
  } = useGetCampaignFlights({ campaignId: PLACEHOLDER_CAMPAIGN_ID });

  const flightsTable = useCampaignFlightsTable({
    data: campaignFlightsData || [],
  });

  const brandName = selectedBrand?.name || 'Brand name';
  const campaignName = 'Campaign name';

  const today = dayjs();
  const tomorrow = today.add(1, 'day');

  // placeholder example campaign
  const campaign = {
    startDate: formatPacificTimeToUtc(today.format('YYYY-MM-DD')),
    isActive: true,
    endDate: formatPacificTimeToUtc(tomorrow.format('YYYY-MM-DD')),
    updatedAt: formatPacificTimeToUtc(today.format('YYYY-MM-DD')),
    editedBy: {
      email: 'user@iheartjane.com',
    },
  };

  const flightDateRange = campaign
    ? `${formatISODate(campaign.startDate)} – ${formatISODate(
        campaign.endDate
      )}`
    : undefined;

  const isEmpty = !isCampaignFlightsLoading && !campaignFlightsData?.length;

  const onEmptyCampaignFlightsClick = (isError: boolean) => {
    if (isError) {
      refetchCampaignFlights();
    }
    // Reset filters
    onChangeFilterParams({
      locations: [],
      types: [],
      startDatePT: DEFAULT_START_DATE,
      endDatePT: null,
      pacing: [],
    });
  };

  return (
    <Flex flexDirection="column" gap={24} mx={40} py={40}>
      <Breadcrumbs>
        <Link to={brandsPaths.digitalMerchandising()}>{brandName}</Link>
        <Link to={brandsPaths.campaigns()}>Campaigns</Link>
        <Typography>{campaignName}</Typography>
      </Breadcrumbs>
      <Flex mt={40}>
        <Box>
          <Flex alignItems="center">
            <Typography variant="header-bold" as="h1">
              {campaignName}
            </Typography>
            <Box ml={8}>
              {campaign.isActive && (
                <Tag background="palm-light" color="palm" label="Active" />
              )}
              {!campaign.isActive && (
                <Tag
                  background="grays-light"
                  color="grays-dark"
                  label="Inactive"
                />
              )}
            </Box>
          </Flex>
          <Typography color="text-light">{flightDateRange}</Typography>
          <Typography color="text-light">
            Last updated by {campaign?.editedBy?.email} on{' '}
            {campaign ? formatISODate(campaign.updatedAt) : ''}
          </Typography>
        </Box>
        <Flex ml="auto">
          {campaign.isActive && (
            <Button loading={false} variant="secondary" label="Deactivate" />
          )}
          <Button variant="secondary" label="Duplicate" ml={16} />
          <Button variant="secondary" label="Edit" ml={16} />
        </Flex>
      </Flex>
      <Flex justifyContent="space-between" pt={24}>
        <StatisticsFilters
          filterParams={filterParams}
          onChangeFilterParams={onChangeFilterParams}
          hasAdTypeFilter
          hasPacingFilter
        />
        <Flex gap={12}>
          <ColumnVisibilityPopover
            table={flightsTable}
            columnOptions={CAMPAIGN_FLIGHTS_COLUMN_VISIBILITY_OPTIONS}
          />
          <Button variant="primary" label="Create flight" />
        </Flex>
      </Flex>
      {isEmpty ? (
        <EmptyTableState
          isError={!!fetchCampaignFlightsError}
          onClick={onEmptyCampaignFlightsClick}
          errorTitle="Couldn't load flights"
          description="No flights match the filters you have selected"
          buttonLabel="View all flights"
        />
      ) : (
        <>
          <Flex mt={24} px={24}>
            <StatisticsDisplay entries={placeholderCampaignStatistics} />
          </Flex>
          <Flex>
            <Table<ApiFlightSummary>
              table={flightsTable}
              isLoading={isCampaignFlightsLoading}
            />
          </Flex>
        </>
      )}
    </Flex>
  );
};
