import { useCallback, useEffect, useState } from 'react';

import { useUserSegments } from '@jane/gold-manager/data-access';
import {
  Banner,
  Button,
  ChevronDownIcon,
  DismissIcon,
  ErrorIcon,
  Flex,
  Link,
  TypeAhead,
  Typography,
} from '@jane/shared/reefer';
import { Form, useFormContext } from '@jane/shared/reefer-hook-form';

import { useSpecialForm } from '../specialFormProvider/specialFormProvider';
import { handleScroll } from '../utils/specialForm.util';
import type { SpecialFormData } from '../utils/useSpecialFormData';

const CustomerBehaviorSelection = ({
  type,
}: {
  type: 'included' | 'excluded';
}) => {
  const [query, setQuery] = useState('');
  const { excludedUserSegments, rules, setRulesValue, userSegments } =
    useSpecialForm();
  const { clearErrors, setError, watch, trigger } =
    useFormContext<SpecialFormData>();
  const campaignType = watch('campaignType');

  const isExclusion = type === 'excluded';
  const key = isExclusion
    ? 'excludes.user_segment_ids'
    : 'includes.user_segment_ids';

  // used to compare and filter out selected segments from the opposite type
  const selectedIds = isExclusion
    ? excludedUserSegments ?? []
    : rules?.includes?.[0].user_segment_ids ?? [];
  const otherSelectedIds = isExclusion
    ? rules?.includes?.[0].user_segment_ids ?? []
    : excludedUserSegments ?? [];

  const { data: segments = [], isLoading } = useUserSegments();

  const selectedSegments = segments
    .filter((segment) => selectedIds.includes(segment.id))
    .map((segment) => segment.description);

  const options = segments
    .filter(
      (segment) =>
        !otherSelectedIds.includes(segment.id) &&
        segment.description.toLowerCase().includes(query.toLowerCase())
    )
    .map((segment) => ({ label: segment.description }));

  const handleSegmentValidation = useCallback(() => {
    trigger('userSegments');
    if (
      (campaignType === 'new' || campaignType === 'lapsed') &&
      !userSegments?.length
    ) {
      setError('userSegments', {
        type: 'required',
        message: 'User segments are required.',
      });
    } else if (campaignType === 'untargeted' && userSegments?.length) {
      setError('userSegments', {
        type: 'required',
        message: "Special type must be 'New' or 'Lapsed'.",
      });
    } else {
      clearErrors('userSegments');
    }
  }, [campaignType, userSegments]);

  const handleOnSelection = (value: string[]) => {
    const ids = segments
      .filter((segment) => value.includes(segment.description))
      .map((segment) => segment.id);

    setRulesValue(key, ids);
  };

  const handleRemoveSegment = (segment: string) => {
    const segmentToRemove = segments.find((s) => s.description === segment);
    const updatedSegments = selectedIds.filter(
      (id) => segmentToRemove?.id !== id
    );

    setRulesValue(key, updatedSegments);
  };

  useEffect(() => {
    handleSegmentValidation();
  }, [handleSegmentValidation]);

  return (
    <Flex flexDirection="column">
      {!isExclusion && (
        <Typography as="label" variant="body-bold" mb={12}>
          Customer behavior
        </Typography>
      )}
      {!campaignType && (
        <Banner
          full
          icon={<ErrorIcon />}
          variant="error"
          actions={
            <Link ml="auto" onClick={() => handleScroll('campaign-type')}>
              Select type
            </Link>
          }
          label="Select a campaign type before including/excluding user segments"
          mb={12}
        />
      )}
      <TypeAhead
        loading={isLoading}
        ariaLabel={`Search for ${type} segments`}
        listAriaLabel="Select a segment from the list or search by name"
        labelHidden
        onChange={setQuery}
        onSelection={(value: string | string[]) => {
          if (Array.isArray(value)) {
            handleOnSelection(value);
          }
        }}
        selection={selectedSegments}
        optionType="checkbox"
        options={options}
        popoverTarget={
          <Button
            variant="tertiary"
            label="All segments"
            endIcon={<ChevronDownIcon size="sm" />}
          />
        }
        query={query}
        data-testid={`${type}-behavior`}
        width="320px"
      />
      {selectedSegments && selectedSegments.length > 0 && (
        <Flex flexDirection="row" gap={24} flexWrap="wrap" pt={24}>
          {selectedSegments.map((segment) => (
            <Button
              label={
                <Typography color="inherit" variant="body-bold">
                  {segment}
                </Typography>
              }
              key={segment}
              aria-label={`Remove ${segment}`}
              startIcon={<DismissIcon size="sm" />}
              variant={isExclusion ? 'destructive-secondary' : 'secondary'}
              onClick={() => handleRemoveSegment(segment)}
            />
          ))}
        </Flex>
      )}
    </Flex>
  );
};

export const SegmentsSelection = () => {
  const { watch } = useFormContext<SpecialFormData>();
  const { setRulesValue } = useSpecialForm();
  const showExcludeSegments = watch('excludeCustomers');

  return (
    <Flex flexDirection="column" gap={24}>
      <Typography variant="body-bold">Customer segments to target</Typography>
      <CustomerBehaviorSelection type="included" />
      <Form.CheckboxField
        name="excludeCustomers"
        label="Exclude customers"
        onChange={(checked) => {
          if (!checked) {
            setRulesValue('excludes.user_segment_ids', []);
          }
        }}
        my={12}
      />
      {showExcludeSegments ? (
        <CustomerBehaviorSelection type="excluded" />
      ) : null}
    </Flex>
  );
};
