import { useInfiniteQuery, useQuery } from '@tanstack/react-query';

import { config } from '@jane/shared/config';
import { api } from '@jane/shared/data-access';
import type {
  BrandSpecial,
  Preferences,
  UserLocation,
} from '@jane/shared/models';
import type { UserSegmentIds } from '@jane/shared/types';
import { encodeQuery, shouldBrandSpecialShowForUser } from '@jane/shared/util';

import { useLocalBrands } from './brands';

interface Params {
  brandIds?: number[];
  ids?: number[];
  limit?: number;
  offset?: number;
  storeIds?: number[];
  userSegments: UserSegmentIds;
}

interface ApiResponse {
  brand_specials: BrandSpecial[];
}

export const fetchBrandSpecials = async ({
  brandIds,
  ids,
  limit,
  offset,
  storeIds,
  userSegments,
}: Params): Promise<ApiResponse['brand_specials'] | undefined> => {
  const params = {
    limit,
    offset,
    ids,
    product_brand_ids: brandIds,
    store_ids: storeIds,
  };

  const url = encodeQuery(`${config.apiPath}/brand_specials`, params, {
    arrayFormat: 'comma',
  });

  const res: ApiResponse = await api.get(url);

  return res.brand_specials.filter((brandSpecial) =>
    shouldBrandSpecialShowForUser({ brandSpecial, userSegments })
  );
};

export const useBrandSpecials = ({
  enabled,
  ...params
}: Params & { enabled: boolean }) =>
  useQuery({
    enabled,
    queryFn: () => fetchBrandSpecials({ ...params }),
    queryKey: ['brandSpecials', params],
  });

export const useLocalBrandSpecials = ({
  enabled,
  brandIds,
  userLocation,
  userPreferences,
  userSegments,
  ...params
}: Params & {
  enabled: boolean;
  userLocation: UserLocation;
  userPreferences?: Preferences;
  userSegments?: UserSegmentIds;
}) => {
  const fetchLocalBrands = enabled;
  const { data: localBrandsData, isLoading: isLoadingBrands } = useLocalBrands({
    enabled,
    brandIds,
    hasBrandDiscount: true,
    hitsPerPage: 1000,
    page: 0,
    storeSpecificProduct: false,
    userLocation,
    userPreferences,
  });

  const applicableBrandSpecialIds =
    localBrandsData?.applicableBrandSpecialIds || [];

  const fetchLocalBrandSpecials =
    applicableBrandSpecialIds.length > 0 && enabled;

  const { data: brandSpecials = [], isLoading: isLoadingBrandSpecials } =
    useBrandSpecials({
      enabled: fetchLocalBrandSpecials,
      ids: applicableBrandSpecialIds.map(Number),
      userSegments,
      ...params,
    });

  const isLoading =
    (fetchLocalBrands && isLoadingBrands) ||
    (fetchLocalBrandSpecials && isLoadingBrandSpecials);

  return {
    isLoading,
    localBrands: localBrandsData?.hits,
    localBrandSpecials: brandSpecials,
  };
};

export const useInfiniteBrandSpecials = ({
  enabled,
  userSegments,
  ...params
}: Params & {
  enabled: boolean;
  perPage: number;
  userSegments?: UserSegmentIds;
}) => {
  return useInfiniteQuery({
    enabled,
    queryFn: async ({ pageParam = 0 }) => {
      const brandSpecials = await fetchBrandSpecials({
        ...params,
        offset: pageParam * params.perPage,
        limit: params.perPage,
        userSegments,
      });

      return { brandSpecials, pageParam };
    },
    queryKey: ['infiniteBrandSpecials', params, userSegments],
    getNextPageParam: (lastPage) => {
      const isLastPage = (lastPage.brandSpecials || []).length < params.perPage;
      return isLastPage ? undefined : lastPage.pageParam + 1;
    },
  });
};
