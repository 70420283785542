import { createColumnHelper } from '@tanstack/react-table';
import type { ColumnMeta } from '@tanstack/react-table';

import type { ApiFlightSummary } from '@jane/ad-manager/data-access';
import {
  formatCurrency,
  formatDatepickerString,
  parseUTCDateTimeInPT,
} from '@jane/ad-manager/util';
import {
  Box,
  Button,
  Link,
  List,
  Loading,
  MoreIcon,
  Popover,
  Typography,
} from '@jane/shared/reefer';

import { SortableTableHeader } from '../SortableTableHeader';
import { StyledPopover } from '../StyledPopover';

const columnHelper = createColumnHelper<ApiFlightSummary>();

interface CustomColumnMeta<TData, TValue> extends ColumnMeta<TData, TValue> {
  headerLabel?: string;
}

const dateStringInPTFromISODate = (isoDateString?: string | null) => {
  if (!isoDateString) return 'Auto';

  return formatDatepickerString(parseUTCDateTimeInPT(isoDateString));
};

type cellContentFormatterParams = {
  contentNamePlural: string;
  foldingThreshold: number;
  withPopover?: boolean;
};

const cellContentFormatter = (
  content: string,
  params: cellContentFormatterParams
) => {
  if (!content) {
    return <Typography color="grays-mid">None</Typography>;
  }

  const contentArray = content.split(',');
  if (contentArray.length === 0) return null;
  if (contentArray.length < params.foldingThreshold) {
    return contentArray.join(', ');
  }

  if (params.withPopover) {
    return (
      <StyledPopover
        target={
          <div>
            {contentArray.length} {params.contentNamePlural}
          </div>
        }
        label="states"
        openOn="hover"
        variant="info"
        noMinWidth={true}
        closeOnTargetClick={false}
      >
        <Popover.Content>
          <Typography>{contentArray.join(', ')}</Typography>
        </Popover.Content>
      </StyledPopover>
    );
  }

  return (
    <div>
      {contentArray.length} {params.contentNamePlural}
    </div>
  );
};

export const getColumns = () => {
  return [
    columnHelper.display({
      id: 'actions',
      cell: ({ row }) => {
        const isLoading = false;

        if (isLoading)
          return (
            <Box pl={24} pt={8}>
              <Loading style={{ position: 'static' }} size="xs"></Loading>
            </Box>
          );

        return (
          <StyledPopover
            label="row-actions"
            target={
              <Button.Icon label="row-actions-button" icon={<MoreIcon />} />
            }
          >
            <Popover.Content label="row-actions-content">
              <List label="row-actions-list">
                <List.Item>
                  <Link variant="minimal">View detail</Link>
                </List.Item>
                <List.Item>
                  <Link variant="minimal" ariaLabel="duplicate flight">
                    Duplicate
                  </Link>
                </List.Item>
                {row.original.active && (
                  <List.Item>
                    <Link
                      variant="minimal"
                      ariaLabel="Deactivate"
                      color="grays-black"
                      style={{ cursor: 'pointer' }}
                    >
                      Deactivate
                    </Link>
                  </List.Item>
                )}
              </List>
            </Popover.Content>
          </StyledPopover>
        );
      },
    }),

    //flight id
    columnHelper.accessor(({ id }) => id.toString(), {
      id: 'id',
      header: ({ column }) => (
        <SortableTableHeader<ApiFlightSummary> label="flight" column={column} />
      ),
      cell: ({ getValue }) => (
        <Typography variant="body-bold">{getValue()}</Typography>
      ),
    }),

    //states
    columnHelper.accessor(({ states }) => states?.sort().toString(), {
      id: 'states',
      header: () => <Typography variant="caps">states</Typography>,
      cell: ({ getValue }) =>
        cellContentFormatter(getValue(), {
          contentNamePlural: 'states',
          foldingThreshold: 4,
          withPopover: true,
        }),
      meta: {
        headerLabel: 'States',
      } as CustomColumnMeta<ApiFlightSummary, string>,
    }),

    // stores
    columnHelper.accessor(
      ({ stores = [] }) =>
        stores
          .map((s) => s.id)
          .sort((a, b) => a - b)
          .toString(),
      {
        id: 'stores',
        header: () => <Typography variant="caps">stores</Typography>,
        cell: ({ getValue }) =>
          cellContentFormatter(getValue(), {
            contentNamePlural: 'stores',
            foldingThreshold: 2,
          }),
        meta: {
          headerLabel: 'Stores',
        } as CustomColumnMeta<ApiFlightSummary, string>,
      }
    ),

    // starts
    columnHelper.accessor('startDate', {
      id: 'startDate',
      header: ({ column }) => (
        <SortableTableHeader<ApiFlightSummary> label="starts" column={column} />
      ),
      cell: ({ getValue }) => dateStringInPTFromISODate(getValue()),
    }),

    // ends
    columnHelper.accessor('endDate', {
      id: 'endDate',
      header: ({ column }) => (
        <SortableTableHeader<ApiFlightSummary> label="ends" column={column} />
      ),
      cell: ({ getValue }) => dateStringInPTFromISODate(getValue()),
    }),

    // type
    columnHelper.accessor('model', {
      id: 'model',
      header: ({ column }) => (
        <SortableTableHeader<ApiFlightSummary> label="type" column={column} />
      ),
      cell: ({ getValue }) => getValue().toUpperCase(),
      meta: {
        headerLabel: 'Type',
      } as CustomColumnMeta<ApiFlightSummary, string>,
    }),

    // products
    columnHelper.accessor(
      ({ selectedProducts = [] }) =>
        selectedProducts
          .map((sp) => sp.id)
          .sort((a, b) => a - b)
          .toString(),
      {
        id: 'products',
        cell: ({ getValue }) =>
          cellContentFormatter(getValue(), {
            contentNamePlural: 'products',
            foldingThreshold: 2,
          }),
        header: () => <Typography variant="caps">products</Typography>,
        meta: {
          headerLabel: 'Products',
        } as CustomColumnMeta<ApiFlightSummary, string>,
      }
    ),

    // placement
    columnHelper.accessor(({ zones }) => zones?.sort().toString(), {
      id: 'placement',
      header: () => <Typography variant="caps">placement</Typography>,
      cell: ({ getValue }) =>
        cellContentFormatter(getValue(), {
          contentNamePlural: 'placements',
          foldingThreshold: 2,
          withPopover: true,
        }),
      meta: {
        headerLabel: 'Placement',
      } as CustomColumnMeta<ApiFlightSummary, string>,
    }),

    // budget
    columnHelper.accessor(
      ({ goalType, dailyBudget, goalAmount }) =>
        goalType === 'percentage'
          ? { budget: dailyBudget, goalType: 'percentage' as const }
          : { budget: goalAmount, goalType },
      {
        id: 'budget',
        header: ({ column }) => (
          <SortableTableHeader<ApiFlightSummary>
            label="budget"
            column={column}
          />
        ),
        cell: ({ getValue, row }) => {
          const { budget, goalType } = getValue();

          if (typeof budget === 'number') {
            const timeUnitSuffixes = {
              monthly_revenue: '/month',
              percentage: '/day',
              revenue: '',
            };
            return `${formatCurrency(budget)}${timeUnitSuffixes[goalType]}`;
          }

          return <Typography color="grays-mid">Not set</Typography>;
        },
        meta: {
          headerLabel: 'Budget',
        } as CustomColumnMeta<ApiFlightSummary, string>,
      }
    ),

    // spend
    columnHelper.accessor('spend', {
      id: 'spend',
      header: ({ column }) => (
        <SortableTableHeader<ApiFlightSummary> label="spend" column={column} />
      ),
      cell: ({ getValue }) =>
        getValue() ? (
          `${formatCurrency(getValue())}`
        ) : (
          <Typography color="grays-mid">Not set</Typography>
        ),
      meta: {
        headerLabel: 'Spend',
      } as CustomColumnMeta<ApiFlightSummary, string>,
    }),

    // bid
    columnHelper.accessor('bid', {
      id: 'bid',
      header: ({ column }) => (
        <SortableTableHeader<ApiFlightSummary> label="bid" column={column} />
      ),
      cell: ({ getValue }) => {
        const value = getValue();

        if (value !== null) {
          return `${formatCurrency(value)}`;
        }

        return <Typography color="grays-mid">Not set</Typography>;
      },
      meta: {
        headerLabel: 'Bid',
      } as CustomColumnMeta<ApiFlightSummary, string>,
    }),

    // frequency cap
    columnHelper.accessor(
      ({ frequencyCapUnit, frequencyCapDenominator, frequencyCapNumerator }) =>
        frequencyCapUnit && frequencyCapUnit !== 'disabled'
          ? `${frequencyCapNumerator}/${frequencyCapDenominator} ${frequencyCapUnit}`
          : undefined,
      {
        id: 'frequencyCap',
        header: () => <Typography variant="caps">frequency cap</Typography>,
        cell: ({ getValue }) =>
          getValue() ?? <Typography color="grays-mid">Not set</Typography>,
        meta: {
          headerLabel: 'Frequency cap',
        } as CustomColumnMeta<ApiFlightSummary, string>,
      }
    ),

    // impressions
    columnHelper.accessor((row) => row.impressions.toString(), {
      id: 'impressions',
      header: ({ column }) => (
        <SortableTableHeader<ApiFlightSummary>
          label="impressions"
          column={column}
        />
      ),
      meta: {
        headerLabel: 'Impressions',
      } as CustomColumnMeta<ApiFlightSummary, string>,
    }),

    // displays on
    columnHelper.accessor('displaysOn', {
      id: 'displaysOn',
      header: ({ column }) => (
        <SortableTableHeader<ApiFlightSummary>
          label="displays on"
          column={column}
        />
      ),
      meta: {
        headerLabel: 'Displays on',
      } as CustomColumnMeta<ApiFlightSummary, string>,
    }),
  ];
};
