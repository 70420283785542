import { z } from 'zod';

import { zId } from './id';
import { zPriceId, zProductWeightEnum } from './menuProduct';
import { zProductKindEnum, zProductLineageEnum } from './product';
import { zPublicImage } from './publicImage';

export const zBrandCondition = z.string();

export const zProductLineageWithAll = z.enum([
  ...zProductLineageEnum.options,
  'all',
]);

export const zKindCondition = z.intersection(
  z.object({ kind: zProductKindEnum }),
  z
    .object({
      lineage: zProductLineageWithAll,
      brand_subtype: z.string(),
      root_subtype: z.string(),
    })
    .partial()
);

export const zWeightCondition = zProductWeightEnum;

const zNumberCondition = z.number();

const zProductThresholdConditions = z
  .object({
    maximum_price: z.number().nullable(),
    minimum_price: z.number().nullable(),
  })
  .partial();

const zSeniorGroupConditions = z.object({
  type: z.literal('senior'),
  required_age: z.number(),
});

const zPediatricGroupConditions = z.object({
  type: z.literal('pediatric'),
  required_age: z.number(),
});

const zQualifiedGroupConditions = z.union([
  zSeniorGroupConditions,
  zPediatricGroupConditions,
  z.object({
    type: z.enum([
      'student',
      'veteran',
      'medical',
      'ssi',
      'snap',
      'industry',
      'medicare',
      'medicaid',
      'member',
      'locals',
      'chip',
      'pace',
      'pacenet',
      'wic',
      'PA Gov',
    ]),
  }),
]);

const zBulkWeightCondition = z.intersection(
  z.object({
    price_id: zPriceId,
  }),
  zProductThresholdConditions
);

const zBulkPricingConditions = z
  .object({
    brands: z.array(zBrandCondition),
    brand_ids: z.array(zNumberCondition).nullable(),
    kinds: z.array(zKindCondition),
    excluded_product_ids: z.array(zNumberCondition),
    included_product_ids: z.array(zNumberCondition).nullable(),
    lineage: z.string(),
    bulk_weights: z.array(zBulkWeightCondition),
    target_weight: zPriceId,
    max_applications_per_cart: z.number().nullable(),
  })
  .partial();

export const zBrandDiscount = z.object({
  id: z.number(),
  brand_special_id: z.number(),
  cart_id: z.number(),
  cart_product_id: z.number(),
  created_at: z.string(),
  discount_amount: z.string(),
  menu_product_id: z.number(),
  status: z.string(),
  updated_at: z.string(),
});

const zProductConditions = z
  .object({
    brands: z.array(zBrandCondition),
    brand_ids: z.array(zNumberCondition).nullable(),
    excluded_product_ids: z.array(zNumberCondition),
    kinds: z.array(zKindCondition),
    weights: z.array(zWeightCondition),
    included_product_ids: z.array(zNumberCondition).nullable(),
    product_threshold: zProductThresholdConditions,
    lineage: z.string(),
  })
  .partial();

const zDependentBundleConditions = z.intersection(
  zProductConditions,
  z.object({
    max_number_of_discounted_products: z.number().nullable(),
  })
);

const zBundleProductConditions = z.object({
  independent: z.intersection(
    zProductConditions,
    z.object({
      threshold_number_of_items_in_cart: z.number().nullable(),
    })
  ),
  dependent: zDependentBundleConditions,
  settings: z.object({
    allow_discounts_on_required_products: z.boolean().nullable(),
  }),
  max_applications_per_cart: z.number().nullable(),
});

// TODO: remove partial once Specials have been repaired
const zCartTotalConditions = z.intersection(
  z
    .object({
      reservation_modes: z.object({
        kiosk: z.boolean(),
        delivery: z.boolean(),
        pickup: z.boolean(),
      }),
    })
    .deepPartial(),
  z.object({
    threshold: z.union([z.number(), z.string()]),
  })
);

const zSchedule = z
  .object({
    // TODO: Once the backend is implemented we should remove the partial
    enabled_friday_all_day: z.boolean().nullable(),
    enabled_monday_all_day: z.boolean().nullable(),
    enabled_saturday_all_day: z.boolean().nullable(),
    enabled_sunday_all_day: z.boolean().nullable(),
    enabled_thursday_all_day: z.boolean().nullable(),
    enabled_tuesday_all_day: z.boolean().nullable(),
    enabled_wednesday_all_day: z.boolean().nullable(),
    end_time_friday: z.string().nullable(),
    end_time_monday: z.string().nullable(),
    end_time_saturday: z.string().nullable(),
    end_time_sunday: z.string().nullable(),
    end_time_thursday: z.string().nullable(),
    end_time_tuesday: z.string().nullable(),
    end_time_wednesday: z.string().nullable(),
    start_time_friday: z.string().nullable(),
    start_time_monday: z.string().nullable(),
    start_time_saturday: z.string().nullable(),
    start_time_sunday: z.string().nullable(),
    start_time_thursday: z.string().nullable(),
    start_time_tuesday: z.string().nullable(),
    start_time_wednesday: z.string().nullable(),
  })
  .partial();

export const zSpecialRules = z
  .object({
    includes: z.array(zProductConditions),
    excludes: z.array(zProductConditions),
  })
  .partial();

export const zSpecialConditions = z
  .object({
    product: zProductConditions,
    cart_total: zCartTotalConditions,
    qualified_group: zQualifiedGroupConditions,
    bundle: zBundleProductConditions,
    bulk_pricing: zBulkPricingConditions,
    created_at: z.string().nullable(),
    updated_at: z.string().nullable(),
  })
  .partial();

const zSpecialStatus = z.enum(['today', 'upcoming', 'disabled', 'expired']);

const zSpecialType = z.enum([
  'product',
  'cart_total',
  'qualified_group',
  'bundle',
  'bulk_pricing',
]);

// TODO: remove partial once Specials have been repaired
const zReservationModes = z.intersection(
  z.object({
    kiosk: z.union([z.void(), z.boolean()]),
    pickup: z.union([z.void(), z.boolean()]),
    delivery: z.union([z.void(), z.boolean()]),
  }),
  z
    .object({
      curbside: z.union([z.void(), z.boolean()]),
    })
    .partial()
);

export const zStoreSpecial = z.intersection(
  z.object({
    id: z.number(),
    description: z.string().nullable(),
    discount_dollar_amount: z.number(),
    discount_percent: z.number(),
    discount_target_price: z.number(),
    discount_type: z.string(),
    multiple_use_promo_code: z.boolean().nullable(),
    photo: zPublicImage.nullable(),
    promo_code: z.string().nullable(),
    reservation_modes: zReservationModes.nullable(),
    special_type: zSpecialType,
    status: zSpecialStatus.nullable(),
    store_id: zId,
    terms: z.string().nullable(),
    title: z.string(),
  }),
  z
    .object({
      abbreviated_store_specials: z.array(
        z
          .object({
            id: z.number(),
            store_id: z.number(),
            enabled: z.boolean(),
            disabled_by_bulk_editing: z.boolean().nullable(),
          })
          .nullable()
      ),
      conditions: zSpecialConditions,
      created_at: z.string().nullable(),
      custom_badge: z.string().nullable(),
      discount_amount: z.string().nullable(),
      enabled_date_end_checked: z.boolean().nullable(),
      enabled_date_end: z.string().nullable(),
      enabled_date_start_checked: z.boolean().nullable(),
      enabled_date_start: z.string().nullable(),
      enabled_friday: z.boolean().nullable(),
      enabled_monday: z.boolean().nullable(),
      enabled_saturday: z.boolean().nullable(),
      enabled_sunday: z.boolean().nullable(),
      enabled_thursday: z.boolean().nullable(),
      enabled_tuesday: z.boolean().nullable(),
      enabled_wednesday: z.boolean().nullable(),
      enabled: z.boolean().nullable(),
      end_date: z.string().nullable(),
      pos_source: z.string().nullable(),
      pos_special_id: z.string().nullable(),
      pos_special_link: z.string().nullable(),
      pos_synced: z.boolean(),
      promo_code_max_number_of_uses: z.number().nullable(),
      rules: zSpecialRules.nullable(),
      schedule: zSchedule.nullable(),
      start_date: z.string().nullable(),
      updated_at: z.string().nullable(),
      use_store_close_time: z.boolean().nullable(),
    })
    .partial()
);

export type BrandCondition = z.infer<typeof zBrandCondition>;
export type BrandDiscount = z.infer<typeof zBrandDiscount>;
export type BulkWeightCondition = z.infer<typeof zBulkWeightCondition>;
export type BulkPricingConditions = z.infer<typeof zBulkPricingConditions>;
export type BundleProductConditions = z.infer<typeof zBundleProductConditions>;
export type CartTotalConditions = z.infer<typeof zCartTotalConditions>;
export type DependentBundleConditions = z.infer<
  typeof zDependentBundleConditions
>;
export type KindCondition = z.infer<typeof zKindCondition>;
export type PediatricGroupConditions = z.infer<
  typeof zPediatricGroupConditions
>;
export type ProductCondition = z.infer<typeof zNumberCondition>;
export type ProductConditions = z.infer<typeof zProductConditions>;
export type ProductRules = z.infer<typeof zSpecialRules>;
export type ProductThresholdConditions = z.infer<
  typeof zProductThresholdConditions
>;
export type QualifiedGroupConditions = z.infer<
  typeof zQualifiedGroupConditions
>;
export type ReservationModes = z.infer<typeof zReservationModes>;
export type SeniorGroupConditions = z.infer<typeof zSeniorGroupConditions>;
export type SpecialConditions = Readonly<z.infer<typeof zSpecialConditions>>;
export type SpecialSchedule = z.infer<typeof zSchedule>;
export type SpecialStatus = z.infer<typeof zSpecialStatus>;
export type SpecialType = z.infer<typeof zSpecialType>;
export type StoreSpecial = z.infer<typeof zStoreSpecial>;
export type WeightCondition = z.infer<typeof zWeightCondition>;
