import { useQuery } from '@tanstack/react-query';

import { handleJamError } from '@jane/ad-manager/util';
import type { ApiRequestError } from '@jane/shared/data-access';

import type { ApiFlightSummary } from '../productBrands/schemas';

// mock campaign flights
const CAMPAIGN_FLIGHTS_DATA: ApiFlightSummary[] = [
  {
    id: 799937236,
    active: true,
    bid: 232323,
    dailyBudget: 23,
    displaysOn: 'All',
    endDate: '2025-04-06T06:59:59',
    frequencyCapNumerator: 2,
    frequencyCapDenominator: 30,
    frequencyCapUnit: 'minutes',
    goalAmount: 100,
    goalType: 'percentage',
    impressions: 0,
    model: 'cpm',
    readOnly: false,
    selectedProducts: [],
    spend: 0,
    startDate: '2025-04-04T07:00:00',
    states: ['AK'],
    stores: [],
    zones: ['Menu row', 'Product page'],
  },
  {
    id: 799937237,
    active: false,
    bid: 50000,
    dailyBudget: 100,
    displaysOn: 'Kiosk',
    endDate: '2025-04-15T06:59:59',
    frequencyCapNumerator: 1,
    frequencyCapDenominator: 60,
    frequencyCapUnit: 'minutes',
    goalAmount: 200,
    goalType: 'percentage',
    impressions: 1200,
    model: 'cpc',
    readOnly: false,
    selectedProducts: [],
    spend: 15000,
    startDate: '2025-04-01T07:00:00',
    states: ['CA'],
    stores: [],
    zones: ['Cart toppers'],
  },
];

interface FetchCampaignFlightsArgs {
  campaignId?: number;
}

const keys = {
  all: ['campaignFlights'] as const,
  getCampaignFlights: (args: FetchCampaignFlightsArgs) =>
    [...keys.all, 'get-campaign-flights', args] as const,
};

export const useGetCampaignFlights = ({
  campaignId,
}: FetchCampaignFlightsArgs) => {
  return useQuery({
    queryKey: keys.getCampaignFlights({ campaignId }),
    queryFn: ({ signal }) => getCampaignFlights(campaignId, signal),
    refetchOnWindowFocus: false,
    onError: (error: ApiRequestError) => handleJamError(error),
    retry: false,
  });
};

const getCampaignFlights = async (
  campaignId?: number,
  signal?: AbortSignal
) => {
  if (!campaignId) return [];
  return CAMPAIGN_FLIGHTS_DATA;
};
