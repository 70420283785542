import type { ColumnMeta } from '@tanstack/react-table';
import { createColumnHelper } from '@tanstack/react-table';
import type { NavigateFunction } from 'react-router-dom';

import {
  formatCurrency,
  formatDatepickerString,
  parseUTCDateTimeInPT,
} from '@jane/ad-manager/util';
import { brandsPaths } from '@jane/brands/util';
import {
  Box,
  Button,
  Link,
  List,
  Loading,
  MoreIcon,
  Popover,
  Typography,
} from '@jane/shared/reefer';

import { SortableTableHeader } from '../SortableTableHeader';
import { StyledPopover } from '../StyledPopover';
import type { RowActions } from './useCampaignsTable';

export interface CustomColumnMeta<TData, TValue>
  extends ColumnMeta<TData, TValue> {
  headerLabel?: string;
}

// temporary mocked type for campaign
export type ApiCampaign = {
  active: boolean;
  endDate: string;
  flightsBudget: number;
  id: number;
  name: string;
  pacing: string;
  startDate: string;
  states: string[];
  targetBudget: number;
};

const columnHelper = createColumnHelper<ApiCampaign>();

const dateStringInPTFromISODate = (isoDateString?: string | null) => {
  if (!isoDateString) return 'Auto';

  return formatDatepickerString(parseUTCDateTimeInPT(isoDateString));
};

type GetColumnsProps = RowActions & {
  loadingCampaignIds: number[];
  navigate: NavigateFunction;
};

export const getColumns = ({
  loadingCampaignIds,
  navigate,
  onSetActive,
}: GetColumnsProps) => {
  return [
    // Actions column
    columnHelper.display({
      id: 'actions',
      cell: ({ row }) => {
        const isLoading = loadingCampaignIds.includes(row.original.id);

        if (isLoading)
          return (
            <Box pl={24} pt={8}>
              <Loading style={{ position: 'static' }} size="xs"></Loading>
            </Box>
          );

        return (
          <StyledPopover
            label="row-actions"
            target={
              <Button.Icon label="row-actions-button" icon={<MoreIcon />} />
            }
          >
            <Popover.Content label="row-actions-content">
              <List label="row-actions-list">
                <List.Item>
                  <Link
                    variant="minimal"
                    onClick={() =>
                      navigate(brandsPaths.campaignFlightList(row.original.id))
                    }
                  >
                    View campaign details
                  </Link>
                </List.Item>
                <List.Item>
                  <Link
                    variant="minimal"
                    onClick={() => {
                      console.log('add a flight');
                    }}
                  >
                    Add a flight
                  </Link>
                </List.Item>
                <List.Item>
                  <Link
                    variant="minimal"
                    onClick={() => {
                      console.log('edit');
                    }}
                  >
                    Edit
                  </Link>
                </List.Item>
                <List.Item>
                  <Link
                    onClick={() => {
                      onSetActive(row.original.id, !row.original.active);
                    }}
                    variant="minimal"
                    ariaLabel={row.original.active ? 'Deactivate' : 'Activate'}
                    data-testid={
                      row.original.active ? 'deactivate' : 'activate'
                    }
                    color="grays-black"
                    style={{ cursor: 'pointer' }}
                  >
                    {row.original.active ? 'Deactivate' : 'Activate'}
                  </Link>
                </List.Item>
              </List>
            </Popover.Content>
          </StyledPopover>
        );
      },
    }),
    columnHelper.display({
      id: 'name',
      header: ({ column }) => (
        <SortableTableHeader<ApiCampaign> label="Campaigns" column={column} />
      ),
      cell: ({ row }) => (
        <Typography variant="body-bold" color="grays-black">
          {row.original.name}
        </Typography>
      ),
    }),
    columnHelper.accessor('id', {
      header: ({ column }) => (
        <SortableTableHeader<ApiCampaign> label="ID" column={column} />
      ),
    }),
    columnHelper.accessor('states', {
      header: ({ column }) => (
        <SortableTableHeader<ApiCampaign> label="States" column={column} />
      ),
      meta: {
        headerLabel: 'States',
      } as CustomColumnMeta<ApiCampaign, string>,
    }),
    columnHelper.accessor('startDate', {
      header: ({ column }) => (
        <SortableTableHeader<ApiCampaign> label="Starts" column={column} />
      ),
      cell: ({ getValue }) => dateStringInPTFromISODate(getValue()),
    }),
    columnHelper.accessor('endDate', {
      header: ({ column }) => (
        <SortableTableHeader<ApiCampaign> label="Ends" column={column} />
      ),
      cell: ({ getValue }) => dateStringInPTFromISODate(getValue()),
    }),
    columnHelper.accessor(
      ({ targetBudget }) => ({
        budget: targetBudget,
      }),
      {
        id: 'targetBudget',
        header: ({ column }) => (
          <SortableTableHeader<ApiCampaign>
            label="Target Budget"
            column={column}
          />
        ),
        cell: ({ getValue }) => {
          const { budget } = getValue();

          if (typeof budget === 'number') {
            return `${formatCurrency(budget)}`;
          }

          return <Typography color="grays-mid">Not set</Typography>;
        },
        meta: {
          headerLabel: 'Target Budget',
        } as CustomColumnMeta<ApiCampaign, string>,
      }
    ),
    columnHelper.accessor(
      ({ flightsBudget }) => ({
        budget: flightsBudget,
      }),
      {
        id: 'flightsBudget',
        header: ({ column }) => (
          <SortableTableHeader<ApiCampaign>
            label="Flights Budget"
            column={column}
          />
        ),
        cell: ({ getValue }) => {
          const { budget } = getValue();

          if (typeof budget === 'number') {
            return `${formatCurrency(budget)}`;
          }

          return <Typography color="grays-mid">Not set</Typography>;
        },
        meta: {
          headerLabel: 'Flights Budget',
        } as CustomColumnMeta<ApiCampaign, string>,
      }
    ),
    columnHelper.accessor('pacing', {
      header: ({ column }) => (
        <SortableTableHeader<ApiCampaign> label="Pacing" column={column} />
      ),
      meta: {
        headerLabel: 'Pacing',
      } as CustomColumnMeta<ApiCampaign, string>,
    }),
  ];
};
