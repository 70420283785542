import type { Column } from '@tanstack/react-table';

import { Flex, Link, SortUpIcon, Typography } from '@jane/shared/reefer';

const sortDirection = {
  asc: 'asc',
  desc: 'desc',
} as const;

export const SortableTableHeader = <T,>({
  column,
  label,
}: {
  column: Column<T>;
  label: string;
}) => {
  const isColumnSorted =
    column.getIsSorted() === sortDirection.asc ||
    column.getIsSorted() === sortDirection.desc;
  const iconColor = isColumnSorted ? 'primary-dark' : 'primary-light';
  const handleSortingToggle = () => {
    const isSorted = column.getIsSorted();
    column.toggleSorting(isSorted === sortDirection.asc);
  };

  return (
    <Link variant="minimal" onClick={handleSortingToggle}>
      <Flex alignItems="center">
        <Typography variant="caps">{label}</Typography>
        <SortUpIcon
          color={iconColor}
          rotate={column.getIsSorted() === sortDirection.asc ? 'up' : 'down'}
        />
      </Flex>
    </Link>
  );
};
