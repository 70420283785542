import styled from '@emotion/styled';

import { Popover } from '@jane/shared/reefer';

// If the popover is toward the bottom of the screen/viewport, it will be cut off.
// This is a workaround to move it up a bit, closer to the target.
export const StyledPopover = styled(Popover)`
  & #popover-content {
    margin-top: 4px;
    margin-left: 14px;
  }
`;
