import type {
  SortingState,
  TableOptions,
  VisibilityState,
} from '@tanstack/react-table';
import {
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useState } from 'react';

import type { ApiFlightSummary } from '@jane/ad-manager/data-access';
import { useColumnVisibilityInStorage } from '@jane/ad-manager/hooks';

import { getColumns } from './getColumns';

const DEFAULT_COLUMN_VISIBILITY_STATE: VisibilityState = {
  states: true,
  stores: false,
  startDate: true,
  endDate: true,
  model: true,
  products: false,
  placement: false,
  dailyBudget: true,
  spend: true,
  bid: true,
  frequencyCap: true,
  impressions: true,
  displaysOn: false,
};

type Props = Omit<
  TableOptions<ApiFlightSummary>,
  'getCoreRowModel' | 'columns'
> & {
  data: ApiFlightSummary[];
};

export const useCampaignFlightsTable = ({ data, ...props }: Props) => {
  const [sorting, setSorting] = useState<SortingState>([
    { desc: true, id: 'id' },
  ]);

  const [columnVisibility, setColumnVisibility] = useColumnVisibilityInStorage(
    'campaignFlights',
    DEFAULT_COLUMN_VISIBILITY_STATE
  );

  const columns = getColumns();

  return useReactTable({
    columns,
    onColumnVisibilityChange: setColumnVisibility,
    state: { columnVisibility, sorting },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    data,
    ...props,
  });
};
