import { useMemo } from 'react';

import { STALE_TIMES } from '@jane/shared/config';
import { useGoldConfig, useUserSegmentIds } from '@jane/shared/data-access';
import type { BrandSpecial } from '@jane/shared/models';
import { useEcommApp } from '@jane/shared/providers';
import type { ProductWithBrandSpecialSegments } from '@jane/shared/util';

import { checkLocationAndStoreId, checkUserSegments } from './utils';

export interface ShouldShowGoldProps {
  brandSpecial?: Pick<BrandSpecial, 'rules'>;
  brandSpecials?: Pick<BrandSpecial, 'rules'>[];
  product?: ProductWithBrandSpecialSegments;
  store?: {
    id?: string | number;
    state?: string | null;
  };
}

export const useShouldShowGold = (props?: ShouldShowGoldProps): boolean => {
  const { appMode } = useEcommApp();

  const { data: goldConfig, isLoading: isLoadingGoldConfig } = useGoldConfig();

  const userSegments = useUserSegmentIds({
    enabled: !!props?.product || !!props?.brandSpecial,
    staleTime: STALE_TIMES.long,
  });

  return useMemo(() => {
    if (isLoadingGoldConfig) return false;
    const validSegments = checkUserSegments({
      ...props,
      userSegments: userSegments || [],
    });

    // check location and store ID and ignore the original janeGold flag
    if (goldConfig) {
      const validState = checkLocationAndStoreId(
        goldConfig,
        appMode,
        props?.store
      );

      return validState && validSegments;
    }

    return validSegments;
  }, [appMode, goldConfig, isLoadingGoldConfig, props, userSegments]);
};
