import { ApiRequestError } from '../types/apiResponseError';

const handleResponse = async (res: Response, returnWholeResponse?: boolean) => {
  // https://tanstack.com/query/v4/docs/guides/query-functions#usage-with-fetch-and-other-clients-that-do-not-throw-by-default
  if (!res.ok) {
    let errorMessage = 'Api Request Failed';

    try {
      // Get the response as text first
      const textResponse = await res.clone().text();

      // Only try to parse as JSON if there's content
      if (textResponse) {
        try {
          const errorData = JSON.parse(textResponse);
          errorMessage = errorData.error || errorMessage;
        } catch {
          // Just use the default message
        }
      }
    } catch {
      // Just use the default message
    }

    throw new ApiRequestError(errorMessage, res);
  } else if (res.status === 204) {
    return null;
  }
  return returnWholeResponse ? res : res.json();
};

export const api = {
  // TODO: This causes an infinite loop if you block a request in the console
  // Not sure if it's caused by React Query or this fetch block
  get: async <T>(url: string, params?: RequestInit): Promise<T> => {
    const response = await fetch(url, {
      ...params,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        ...params?.headers,
      },
      method: 'GET',
    });
    return handleResponse(response);
  },
  post: async <T>(
    url: string,
    data: unknown,
    params?: RequestInit,
    returnWholeResponse?: boolean
  ): Promise<T> => {
    const response = await fetch(url, {
      ...params,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        ...params?.headers,
      },
      body: JSON.stringify(data),
      method: 'POST',
    });
    return handleResponse(response, returnWholeResponse);
  },
  patch: async <T>(
    url: string,
    data: unknown,
    params?: RequestInit,
    returnWholeResponse?: boolean
  ): Promise<T> => {
    const response = await fetch(url, {
      ...params,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        ...params?.headers,
      },
      body: JSON.stringify(data),
      method: 'PATCH',
    });
    return handleResponse(response, returnWholeResponse);
  },
  put: async <T>(
    url: string,
    data: unknown,
    params?: RequestInit
  ): Promise<T> => {
    const response = await fetch(url, {
      ...params,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        ...params?.headers,
      },
      body: JSON.stringify(data),
      method: 'PUT',
    });
    return handleResponse(response);
  },
  delete: async <T>(url: string, params?: RequestInit): Promise<T | null> => {
    const response = await fetch(url, {
      ...params,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        ...params?.headers,
      },
      method: 'DELETE',
    });
    return handleResponse(response);
  },
};
