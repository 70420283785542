import { useQuery } from '@tanstack/react-query';

import { handleJamError } from '@jane/ad-manager/util';
import type { ApiRequestError } from '@jane/shared/data-access';

// mocked campaigns data
const CAMPAIGNS_DATA = [
  {
    id: 1,
    active: true,
    name: 'Campaign 1',
    states: ['CA', 'NY'],
    startDate: '2025-01-01',
    endDate: '2025-03-01',
    targetBudget: 1000,
    flightsBudget: 1000,
    pacing: 'On target',
  },
  {
    id: 2,
    active: true,
    name: 'Campaign 2',
    states: ['CA', 'NY'],
    startDate: '2024-02-01',
    endDate: '2024-02-01',
    targetBudget: 1000,
    flightsBudget: 1000,
    pacing: 'Overspending',
  },
  {
    id: 3,
    active: false,
    name: 'Campaign 3',
    states: ['CA', 'NY'],
    startDate: '2024-05-01',
    endDate: '2024-07-01',
    targetBudget: 1000,
    flightsBudget: 1000,
    pacing: 'Underspending',
  },
];

const keys = {
  all: ['campaigns'] as const,
  getCampaigns: (args: FetchCampaignsArgs) =>
    [...keys.all, 'get-campaigns', args] as const,
};

interface FetchCampaignsArgs {
  brandId?: number;
}

export const useGetCampaigns = ({ brandId }: FetchCampaignsArgs) => {
  return useQuery({
    queryKey: keys.getCampaigns({ brandId }),
    queryFn: async ({ signal }) => getCampaigns(brandId, signal),
    refetchOnWindowFocus: false,
    onError: (error: ApiRequestError) => handleJamError(error),
    retry: false,
  });
};

const getCampaigns = async (brandId?: number, signal?: AbortSignal) => {
  if (!brandId) return Promise.resolve([]); //  TODO: add fallback data

  return CAMPAIGNS_DATA;
};
