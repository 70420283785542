import { useEffect, useState } from 'react';

import { useGetCampaigns } from '@jane/ad-manager/data-access';
import { DEFAULT_START_DATE, useFilterParams } from '@jane/ad-manager/hooks';
import { useSelectedBrandContext } from '@jane/ad-manager/providers';
import { Box, Button, Flex } from '@jane/shared/reefer';

import { BrandSelectHeader } from '../Header/BrandSelectHeader';
import type { ApiCampaign } from './Campaigns/getColumns';
import { useCampaignsTable } from './Campaigns/useCampaignsTable';
import { ColumnVisibilityPopover } from './ColumnVisibilityPopover';
import { EmptyTableState } from './EmptyTableState';
import { NoBrandSelectedState } from './NoBrandSelectedState';
import { StatisticsDisplay } from './StatisticsDisplay';
import { StatisticsFilters } from './StatisticsFilters';
import { Table } from './Table';

const CAMPAIGN_COLUMN_VISIBILITY_OPTIONS = [
  'dateRange',
  'flightsBudget',
  'pacing',
  'states',
  'targetBudget',
];

export const Campaigns = () => {
  const [isSearchOpen, setIsSearchOpen] = useState(false);

  const { selectedBrand, setSelectedBrand } = useSelectedBrandContext();
  const { filterParams, onChangeFilterParams } = useFilterParams();

  useEffect(() => {
    // If no brand is selected, open the search modal (initial load)
    if (!selectedBrand) setIsSearchOpen(true);
  }, [selectedBrand]);

  const {
    data: campaignsData,
    isLoading: isCampaignsLoading,
    error: fetchCampaignsError,
    refetch: refetchCampaigns,
  } = useGetCampaigns({ brandId: selectedBrand?.id });

  const campaignsTable = useCampaignsTable({
    data: campaignsData || [],
  });

  const openCampaignBuilder = () => {
    console.log('openCampaignBuilder');
  };

  const campaignsStatistics = [
    {
      label: 'Campaigns live',
      valueDisplay: 4,
    },
    {
      label: 'Flights live',
      valueDisplay: 15,
    },
    {
      label: 'Total target budget',
      valueDisplay: 500,
    },
    {
      label: 'Total flight budget',
      valueDisplay: 100,
    },
  ];

  const isEmpty = !isCampaignsLoading && !campaignsData?.length; // No results
  const isError = !!fetchCampaignsError; // have issues loading campaigns
  const onEmptyCampaignClick = (isError: boolean) => {
    if (isError) {
      // Re-trigger query
      refetchCampaigns();
    }

    // Reset filters and get default view results
    onChangeFilterParams({
      locations: [],
      types: [],
      startDatePT: DEFAULT_START_DATE,
      endDatePT: null,
      pacing: [],
    });
  };

  return (
    <Box>
      {selectedBrand ? (
        <Box>
          <BrandSelectHeader
            hasBoxShadow={false}
            brand={selectedBrand}
            setBrand={setSelectedBrand}
            isSearchOpen={isSearchOpen}
            setIsSearchOpen={setIsSearchOpen}
          />
          <Flex justifyContent="space-between" px={24} pt={24}>
            <StatisticsFilters
              filterParams={filterParams}
              onChangeFilterParams={onChangeFilterParams}
              hasAdTypeFilter={false}
            />
            <Flex gap={12}>
              <ColumnVisibilityPopover<ApiCampaign>
                table={campaignsTable}
                columnOptions={CAMPAIGN_COLUMN_VISIBILITY_OPTIONS}
              />
              <Button
                variant="primary"
                label="Create Campaign"
                data-testid="create-campaign"
                onClick={openCampaignBuilder}
              />
            </Flex>
          </Flex>
          {isEmpty ? (
            <EmptyTableState
              isError={isError}
              onClick={onEmptyCampaignClick}
              errorTitle="Couldn't load campaigns"
              description="No campaigns match the filters you have selected"
              buttonLabel="View all campaigns"
            />
          ) : (
            <>
              <Flex mt={24} px={24}>
                <StatisticsDisplay entries={campaignsStatistics} />
              </Flex>
              <Table<ApiCampaign>
                table={campaignsTable}
                isLoading={isCampaignsLoading}
              />
            </>
          )}
        </Box>
      ) : (
        <Box>
          <BrandSelectHeader
            hasBoxShadow={false}
            brand={selectedBrand}
            setBrand={setSelectedBrand}
            isSearchOpen={isSearchOpen}
            setIsSearchOpen={setIsSearchOpen}
          />
          <NoBrandSelectedState onClick={() => setIsSearchOpen(true)} />
        </Box>
      )}
    </Box>
  );
};
