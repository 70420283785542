import styled from '@emotion/styled';
import sparklyBackground from 'shared/assets/sparkly-background.svg';

import {
  getJaneGoldFAQPath,
  getJaneGoldLandingPath,
} from '@jane/shared-ecomm/util';
import {
  Box,
  ConditionalWrapper,
  Flex,
  JaneGoldShadowLogo,
  JaneLogo,
  Typography,
} from '@jane/shared/reefer';

import { LinkStyled } from './janeGoldCard.styles';

const SparklyBackground = styled.div({
  background: `url("${sparklyBackground}")`,
  backgroundPosition: 'center',
  width: '100%',
  height: '100%',
});

export interface JaneGoldCardProps {
  isJaneGoldMember: boolean;
}

/**
 * `JaneGoldCard` is used to render the special card promoting Jane Gold.
 */

export const JaneGoldCard = ({ isJaneGoldMember }: JaneGoldCardProps) => {
  const href = isJaneGoldMember
    ? getJaneGoldFAQPath()
    : getJaneGoldLandingPath();

  return (
    <ConditionalWrapper
      condition={Boolean(href)}
      wrapper={(wrapped) => (
        <LinkStyled href={href} variant="minimal" target="_blank">
          {wrapped}
        </LinkStyled>
      )}
    >
      <Box background="gold-light" width="100%" height="100%" borderRadius="sm">
        <SparklyBackground>
          <Flex
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            width="100%"
            height="100%"
          >
            <JaneLogo
              ariaLabel="Jane Technologies logo (jane gold promo card)"
              size="xs"
            />
            <JaneGoldShadowLogo height={61} width={206} />
            <Typography color="grays-black" textAlign="center">
              Join the community to get cash back
              <br />
              rewards from your favorite brands
            </Typography>
          </Flex>
        </SparklyBackground>
      </Box>
    </ConditionalWrapper>
  );
};
